import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import StoerkIdAPI from '../../../redux/stoerkID/StoerkIdAPI';
import useParseErrorMessage from '../../../handlingErrors/useParseErrorMessage';
import { useAppSelector } from '../../../redux/store.model';
import {
  getAccountsByWorkspace,
  getWorkspacesFromUserId,
} from '../../../redux/stoerkID/selectors/StoerkId.selectors';
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import { getPolyglot } from '../../../i18n';
import useWatchError from '../../../handlingErrors/useWatchError';
import StoerkIdDetails from '../StoerkIdDetails/StoerkIdDetails';
import { WorkspaceRequestIdParams } from '../util/StoerkId.constants';
import { WorkspaceAPI } from '../../../redux/stoerkID/StoerkIdAPI.model';

function CustomerDetailPage() {
  const params = useParams<WorkspaceRequestIdParams>();
  const { requestCreatorId, workspaceId: workspaceIdParam } = params;
  const polyglot = getPolyglot();
  const {
    error: errorWorkspaces,
    isLoading: isLoadingWorkspaces,
    isError: isErrorWorkspaces,
  } = StoerkIdAPI.useGetAllWorkspaceQuery(undefined);

  const {
    data: salesTeamRequests,
    error: errorSalesTeamRequests,
    isLoading: isLoadingSalesTeamRequests,
    isError: isErrorSalesTeamRequests,
  } = StoerkIdAPI.useGetSalesTeamRequestsQuery(undefined);

  const parseErrorMessage = useParseErrorMessage();
  const items = useAppSelector(getAccountsByWorkspace);

  const current = useMemo(
    () =>
      items.find(
        (request) =>
          request.lastRequest &&
          request.lastRequest?.creatorId === requestCreatorId
      ) || items.find((request) => request.workspace?.id === workspaceIdParam),
    [items, requestCreatorId]
  );

  const workspaceId = workspaceIdParam
    ? workspaceIdParam
    : current?.workspaceId;

  const workspace = useMemo(
    () =>
      items.find((request) => request.workspace?.id === workspaceId)?.workspace,
    [items, workspaceId]
  );

  const {
    error: errorBillingInfo,
    isLoading: isLoadingBillingInfo,
    isError: isErrorBillingInfo,
  } = StoerkIdAPI.useGetBillingInfoFromWorkspaceQuery(workspaceId, {
    skip: !workspaceId,
  });

  const loading =
    isLoadingSalesTeamRequests || isLoadingWorkspaces || isLoadingBillingInfo;
  const isError =
    isErrorSalesTeamRequests || isErrorWorkspaces || isErrorBillingInfo;
  const error = errorSalesTeamRequests || errorWorkspaces || errorBillingInfo;
  useWatchError(error);

  const requestActive = current?.lastRequest;

  return (
    <Container maxWidth="xl" sx={{ padding: 3, height: '100%' }} disableGutters>
      <Box>
        <Typography variant="h1">
          {polyglot.t('stoerk_id.customer_list.title')}
        </Typography>
        <Typography variant="body2">
          {polyglot.t('stoerk_id.customer_list.note')}
        </Typography>

        <Box mt={2}>
          {loading && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100px"
            >
              <CircularProgress />
            </Box>
          )}

          {isError && (
            <Alert severity="error">{parseErrorMessage(error)}</Alert>
          )}

          {salesTeamRequests && !loading && !isError && (
            <Container maxWidth="lg" disableGutters>
              <StoerkIdDetails
                workspace={workspace}
                workspaces={workspace ? [workspace] : []}
                requests={current?.requests}
                requestActive={requestActive}
                showAccountData
                showRequestInfo={requestActive?.requestStatus !== 'ACTIVE'}
                showBillingInfo
                showAutoCharge
                editAutoCharge
                showRequestWorkspaceActions={
                  requestActive?.requestStatus !== 'ACTIVE' &&
                  requestActive?.requestStatus !== 'REJECTED'
                }
                showWorkspaceActions
              />
            </Container>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default CustomerDetailPage;
