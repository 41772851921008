import React, { useCallback } from 'react';
import { DialogModal, ModalContent } from '../../../theme/components';
import { getPolyglot } from '../../../i18n';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../redux/store.model';
import { getFeatureToggle } from '../../../featureToggle';
import { first } from 'lodash';
import useWatchError from '../../../handlingErrors/useWatchError';
import StoerkIdAPI from '../../../redux/stoerkID/StoerkIdAPI';

const STOERK_ID_NO_PLAN_MODAL_DATE_KEY = 'ST_StoerkIdNoPlanModalDate';
/** If not hast workspace show every day */
function StoerkIdNoPlanModal() {
  const polyglot = getPolyglot();
  const navigate = useNavigate();
  const [rememberMe, setRememberMeAndSave] = useRememberMe(
    STOERK_ID_NO_PLAN_MODAL_DATE_KEY
  );
  const { hasWorkspaceOrRequest, isError, isLoading } =
    useHasWorkspaceOrRequest();
  const featureToggle = useAppSelector(getFeatureToggle);
  if (!featureToggle.stoerkId) return null;

  const showModal =
    !isError && !isLoading && !hasWorkspaceOrRequest && rememberMe;

  return (
    <DialogModal
      maxWidth={'md'}
      open={showModal}
      onConfirm={() => {
        navigate('/account');
      }}
      confirmText={polyglot.t(
        'stoerk_id.select_plan_notification.confirm_button'
      )}
      onClose={setRememberMeAndSave}
      cancelText={polyglot.t(
        'stoerk_id.select_plan_notification.cancel_button'
      )}
    >
      <ModalContent>
        <Typography fontSize="1.4rem" variant="body1">
          {polyglot.t('stoerk_id.select_plan_notification.title')}
        </Typography>
        <Box textAlign={'initial'} mt={2}>
          <Typography>
            {polyglot.t('stoerk_id.select_plan_notification.announcement')}
          </Typography>
          <Typography mt={2}>
            {polyglot.t('stoerk_id.select_plan_notification.recommendation')}
          </Typography>
          <Typography mt={2}>
            {polyglot.t('stoerk_id.select_plan_notification.access_change')}
          </Typography>
          <Typography mt={2}>
            {polyglot.t('stoerk_id.select_plan_notification.call_to_action')}
          </Typography>
          <Typography mt={2}>
            {polyglot.t('stoerk_id.select_plan_notification.contact_info')}
          </Typography>
          <Typography mt={2}>
            {polyglot.t('stoerk_id.select_plan_notification.closing')}
          </Typography>
        </Box>
      </ModalContent>
    </DialogModal>
  );
}

const useRememberMe = (key: string, nDays = 1) => {
  const dateSaved = localStorage.getItem(key);
  const date = dateSaved ? moment(dateSaved) : null;
  const isAfterOrNull = !date || moment().isAfter(date);
  const [rememberMe, setRememberMe] = React.useState<boolean>(isAfterOrNull);

  const setRememberMeAndSave = useCallback(() => {
    localStorage.setItem(key, moment().add(nDays, 'days').toISOString());
    setRememberMe(false);
  }, [key, nDays]);

  return [rememberMe, setRememberMeAndSave] as const;
};

const useHasWorkspaceOrRequest = () => {
  const {
    data: workspaces,
    error: errorWorkspace,
    isLoading: isLoadingWorkspace,
    isError: isErrorWorkspace,
  } = StoerkIdAPI.useGetMyWorkspacesQuery(undefined);
  const {
    data: requests,
    error: errorRequests,
    isLoading: isLoadingRequests,
    isError: isErrorRequests,
  } = StoerkIdAPI.useGetMyRequestsQuery(undefined);
  const workspace = first(workspaces);
  const isLoading = isLoadingWorkspace || isLoadingRequests;
  const isError = isErrorWorkspace || isErrorRequests;
  const error = errorWorkspace || errorRequests;
  useWatchError(error);

  const requestActive = first(requests);
  const hasWorkspaceOrRequest = workspace || requestActive;
  return { hasWorkspaceOrRequest, isLoading, isError, error };
};

export default StoerkIdNoPlanModal;
