import React from 'react';
import { Grid } from '@mui/material';
import MerchandisePanel from '../Panels/MerchandisePanel';
import AlarmPanel from '../../../../../components/Dashboard/Panels/AlarmPanel';
function DashboardPageFW() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <MerchandisePanel />
      </Grid>
      <Grid item xs={12} md={6}>
        <AlarmPanel />
      </Grid>
    </Grid>
  );
}

export default DashboardPageFW;
