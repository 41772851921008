import React from 'react';
import { useHasDevicesRestricted } from '../../../redux/stoerkID/hooks';
import { StoerkIdRestrictionsSlice } from '../../../redux/stoerkID/slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store.model';
import { restrictionRememberMeUntilSelector } from '../../../redux/stoerkID/selectors/StoerkIdRestrictions.selectors';
import { DialogModal, ModalContent } from '../../../theme/components';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getPolyglot } from '../../../i18n';
import { Box, Link, Skeleton, Typography } from '@mui/material';
import {
  getMyWorkspaceSelector,
  nDevicesRestrictedSelector,
} from '../../../redux/stoerkID/selectors/StoerkId.selectors';
import StoerkIdDevices from '../StoerkIdDetails/StoerkIdDevices';
import StoerkIdAPI from '../../../redux/stoerkID/StoerkIdAPI';
import useWatchError from '../../../handlingErrors/useWatchError';
import { useUserRightUtil } from '../../../util/rights';

export function StoerkIdInsufficientDevicesQuotaModal() {
  const polyglot = getPolyglot();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { hasDevicesRestricted, loading, error } = useHasDevicesRestricted();
  const { setRememberMeUntil } = StoerkIdRestrictionsSlice.actions;
  const rememberMeUntil = useAppSelector(restrictionRememberMeUntilSelector);
  const workspace = useAppSelector(getMyWorkspaceSelector);
  const nDevicesRestricted = useAppSelector(nDevicesRestrictedSelector);

  const {
    data: workspaceUsage,
    isFetching: isLoadingWorkspaceUsage,
    error: errorWorkspaceUsage,
  } = StoerkIdAPI.useGetWorkspaceUsageQuery(workspace?.id ?? '', {
    refetchOnMountOrArgChange: true,
    skip: !workspace,
  });

  const nDeviceUsed = workspaceUsage?.commanders.length ?? 0;
  const nDevicesToRemove = nDeviceUsed - nDevicesRestricted;
  const userRightUtil = useUserRightUtil();
  const showDeleteDevices = userRightUtil.canViewWorkspaceDevices(
    workspace?.id ?? ''
  );

  useWatchError(errorWorkspaceUsage);

  // Has some delay because is a not pure function, but it change one time per day
  const rememberNow = rememberMeUntil < Date.now();
  const showModal = hasDevicesRestricted && rememberNow && !loading && !error;
  const setRememberMeAndSave = () => {
    dispatch(setRememberMeUntil(moment().add(1, 'day').valueOf()));
  };

  const confirmText = !workspace
    ? polyglot.t('stoerk_id.select_plan_notification.confirm_button')
    : polyglot.t('stoerk_id.nav_title');
  const emailBody = `
    Dear Support Team,
    
    I want to request an increase in my devices resource quota for Cloud Connect Account.
    
    Cloud Connect Account: ${workspace?.name}
    Cloud Connect Account reference: ${workspace?.id}
    `;
  if (!showModal) return null;

  const removeDeviceInstructions =
    showDeleteDevices && workspace ? (
      <>
        <Typography>
          {polyglot
            .t(
              'stoerk_id.devices_restricted_notification.delete_device_instruction'
            )
            .replaceAll('#n_devices_to_remove', nDevicesToRemove.toString())}
        </Typography>
        <Box textAlign="left" mt={2}>
          <StoerkIdDevices workspaceId={workspace.id} />
        </Box>
      </>
    ) : (
      <Typography>
        {polyglot.t(
          'stoerk_id.devices_restricted_notification.request_delete_administrator'
        )}
      </Typography>
    );

  return (
    <DialogModal
      maxWidth={'md'}
      open={showModal}
      onConfirm={() => {
        navigate('/account');
      }}
      confirmText={confirmText}
      onClose={setRememberMeAndSave}
      cancelText={polyglot.t(
        'stoerk_id.select_plan_notification.cancel_button'
      )}
    >
      <ModalContent>
        <Typography fontSize="1.4rem" variant="body1">
          {polyglot.t('stoerk_id.devices_restricted_notification.title')}
        </Typography>
        <Typography mt={2}>
          {isLoadingWorkspaceUsage ? (
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          ) : (
            polyglot
              .t('stoerk_id.devices_restricted_notification.body')
              .replaceAll('#n_devices_free', nDevicesRestricted.toString())
              .replaceAll('#n_devices', nDeviceUsed.toString())
          )}
        </Typography>

        {!!workspace && (
          <>
            <Typography mt={2}>
              {polyglot.t(
                'stoerk_id.devices_restricted_notification.contact_sales'
              )}
            </Typography>
            <Typography variant="body2">
              <Link
                href={`mailto:${polyglot.t(
                  'stoerk_id.support_email'
                )}?subject=Request to increase Cloud Connect Account devices quota&body=${emailBody}`}
              >
                {polyglot.t('stoerk_id.support_email')}
              </Link>
            </Typography>
            <Typography m={2}>{polyglot.t('general.or')}</Typography>
            {removeDeviceInstructions}
          </>
        )}
      </ModalContent>
    </DialogModal>
  );
}

export default StoerkIdInsufficientDevicesQuotaModal;
