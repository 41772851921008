import React from 'react';
import Link from '../../../../../theme/components/Link';
import DashboardPanel from '../../../../../components/Dashboard/Panels/DashboardPanel';
import { MerchandiseIcon } from '../../../assets';
import { Box, Skeleton, Typography } from '@mui/material';
import useDevicesByParams from '../../../../../redux/devices/hooks/useDevicesByParams';

function MerchandisePanel() {
  const { devicesIds, loading } = useDevicesByParams();

  return (
    <Link to={'/devicemanager'}>
      <DashboardPanel>
        <MerchandiseIcon height={'6rem'} />
        <Box>
          <Typography variant="h4" textAlign={'right'}>
            Merchandise
          </Typography>
          <Typography
            variant="h6"
            fontSize={'5rem'}
            lineHeight={'5rem'}
            textAlign={'right'}
          >
            {loading ? <Skeleton width={100} /> : devicesIds.length}
          </Typography>
        </Box>
      </DashboardPanel>
    </Link>
  );
}

export default MerchandisePanel;
