/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import { Box, Typography } from '@mui/material';
import './TermsContent.scss';
import { LightTable } from '../../../theme/components';

function TermsContent() {
  return (
    <>
      <Box className="static-bullets" px={1}>
        <Typography variant="h1" mb={1}>
          General Terms and Conditions of STÖRK-TRONIC STÖRK GmbH & Co. KG for
          the Use of the SaaS Solution Cloud Connect 2024
        </Typography>
        <Typography variant="h3" color={'primary'} component={'li'}>
          1. Scope of application / changes to GTC{' '}
        </Typography>
        <ol>
          <Typography component={'li'}>
            1.1. The provisions of these General Terms and Conditions (“GTC”)
            shall apply to contracts between STÖRK-TRONIC STÖRK GmbH & Co. KG
            use of software as a service (SaaS) and the provision of IT-specific
            services. This shall also apply to future contracts, even if no
            further reference is made to the GTC.{' '}
          </Typography>
          <Typography component={'li'}>
            1.2. When concluding a SaaS contract, the GTC shall apply with
            subordinate effect, unless a provision in the SaaS contract
            expressly stipulates otherwise. These GTC shall only apply to
            companies that are natural persons or legal entities or partnerships
            with legal capacity within the meaning of § 14 BGB (German Civil
            Code) who are acting in the exercise of their commercial or
            self-employed professional activity as well as to legal entities
            under public law and special entities under public law within the
            meaning of § 310 para. 1 BGB.{' '}
          </Typography>
          <Typography component={'li'}>
            1.3. Any changes or amendments as well as any conflicting or
            deviating contractual terms and conditions of the Customer shall
            only apply if STÖRK has expressly agreed to them in text form. If
            the Customer does not agree to this, it shall inform STÖRK of this
            immediately, observing at least text form.{' '}
          </Typography>
          <Typography component={'li'}>
            1.4. STÖRK shall be entitled to adjust the provisions of these GTC
            and the provisions of the SaaS contract (which takes precedence) in
            accordance with the following conditions. The right to make
            adjustments in this context extends in particular to changes
            relating to (i) technical developments, (ii) changes in the legal
            and regulatory framework, (iii) adjustments to the rules for
            handling personal data, (iv) the rectification of an imbalance that
            arises retrospectively in the performance owed reciprocally by the
            parties, or (v) the rectification of gaps in the provisions (e.g. in
            the event of unforeseen changes in circumstances). STÖRK shall
            notify the Customer of such adjustments no later than eight (8)
            weeks before they come into effect, observing at least text form.
            The Customer may object to the adjustments, observing at least in
            text form, within four weeks of receipt of the notification,
            otherwise the adjustment shall be deemed approved. STÖRK shall
            expressly point this out to the Customer in the notification of the
            adjustment. Should the Customer object, STÖRK shall be entitled to
            terminate the contract concluded with the Customer for good cause,
            at least in text form, subject to a notice period of two weeks
            commencing on the date of notification. Amendments to the GTC that
            include an expansion of the services, corrections in wording,
            clarifications or rephrasing without any changes to the content or
            legal aspects do not constitute adjustments within the meaning of
            this provision. STÖRK shall inform the Customer about the expansion
            of services in text form.
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          2. Services / conclusion of contract / subcontractors
        </Typography>
        <ol>
          <Typography component={'li'}>
            2.1. The contractual services are set out in the SaaS contract.
          </Typography>
          <Typography component={'li'}>
            2.2. STÖRK shall be entitled to subcontract the contractual services
            to third parties. The use of subcontractors shall not release STÖRK
            of its responsibility towards the Customer to fulfil the contract in
            full.
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          3. Service provision / deadlines / force majeure
        </Typography>
        <ol>
          <Typography component={'li'}>
            3.1. STÖRK shall provide the contractual services in coordination
            with the Customer on the dates agreed, such agreement observing at
            least text form. STÖRK project managers or company management are
            the only ones authorized to make commitments regarding deadlines for
            the provision of services.{' '}
          </Typography>
          <Typography component={'li'}>
            3.2. If STÖRK carries out a feasibility study for the use of SaaS
            services at the Customer's request, STÖRK will make a positive or
            negative statement about the provision of services and the
            feasibility of the order. Should the review come to a negative
            conclusion, STÖRK will, as a rule, decline to enter into a SaaS
            contract. The costs of the feasibility study shall always be borne
            by the Customer, irrespective of the results of the feasibility
            study.
          </Typography>
          <Typography component={'li'}>
            3.3. To the extent that STÖRK is prevented from providing services
            due to an unforeseen event which cannot be averted through the
            application of reasonable care (“force majeure”), in particular
            natural disasters, epidemics, pandemics (e.g. Covid-19), war,
            blockades, embargos, disruptions to the energy supply or to business
            operations, energy shortages, government orders, legal prohibitions,
            labour action or other cases of force majeure, the deadlines for the
            services are deemed extended by the duration of the obstacle and by
            a reasonable lead-time period after the end of the obstacle
            (“Obstacle to the Performance”). While the Obstacle to the
            Performance prevails, breach of contract shall not apply. STÖRK
            shall notify the Customer of any such Obstacle to the Performance
            and its expected duration without undue delay. As long as STÖRK is
            prevented from rendering contractual performance due to force
            majeure, the Customer shall be released from its performance
            obligation as well. If the force majeure continues uninterrupted for
            more than two months, or if the force majeure makes it impossible
            for STÖRK to render its performance, the Customer and STÖRK shall be
            released from their respective performance obligations.
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          4. Change requests{' '}
        </Typography>
        <ol>
          <Typography component={'li'}>
            4.1. In particular the following requests for changes, additional
            services or extensions shall be considered Change Requests (CR),
            which are each likely to result in additional costs. CR must be
            submitted to STÖRK using at least e-mail.{' '}
          </Typography>
          <Typography component={'li'}>
            4.2. If the CR is feasible and reasonable from STÖRK's point of
            view, STÖRK will submit a binding offer to the Customer. The
            Customer shall communicate its acceptance or rejection of the offer
            in a timely fashion. Upon acceptance by the Customer, the offer
            shall become part of the contract, including with regard to
            performance dates. Any delays caused by the review of the CR and
            occurring up to the acceptance of the offer or the acceptance of the
            bid, such as downtimes in the context of the fulfilment of the
            contract, shall extend the contractually agreed execution period
            appropriately.{' '}
          </Typography>
          <Typography component={'li'}>
            4.3. The deadlines affected by the change process will be postponed,
            taking into account the duration of the review and the CR to be
            carried out, plus an appropriate lead time, unless STÖRK's offer
            accepted by the Customer contains relevant provisions for postponing
            the performance schedule. STÖRK shall inform the Customer of the new
            dates at least in text form. If the Customer fails to place the
            order, the original scope of performance remains in effect.
          </Typography>
          <Typography component={'li'}>
            4.4. The Customer shall bear the costs of STÖRK's activities for
            reviewing the CR, preparing a concept or preparing an offer, as well
            as any downtimes, in accordance with the prices indicated in the
            agreed price list.
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          5. Customer's obligations to provide cooperation
        </Typography>
        <ol>
          <Typography component={'li'}>
            5.1. The cooperation of the Customer is of essential importance for
            the contractual performance owed by STÖRK.
          </Typography>
          <Typography component={'li'}>
            5.2. In particular, the Customer shall fulfil the obligations to
            cooperate listed in lit. a. to h. free of charge to STÖRK in the
            context of STÖRK rendering its contractually owed performance:
          </Typography>
          <ol>
            <Typography component={'li'}>
              a. The Customer shall provide the required number of competent,
              professionally qualified personnel with project-specific
              experience and decision-making authority for the duration of the
              contractual performance. The Customer shall appoint personnel to
              fulfil the functions to be performed by the Customer in this
              regard at the start of the contract. The Customer shall be
              permitted to replace appointed and provided personnel for
              important operational reasons. The Customer shall make any
              decisions necessary for the performance of the contract promptly
              after STÖRK notifies the Customer of the need for such a decision.
            </Typography>
            <Typography component={'li'}>
              b. The Customer shall use its best efforts to support STÖRK's
              activities required for the performance of the contractual
              performance. Above all, this includes active participation in all
              discussions.{' '}
            </Typography>
            <Typography component={'li'}>
              c. The Customer shall create all (technical) prerequisites in the
              area of its business sphere which are necessary for the fulfilment
              of the contractual performance.{' '}
            </Typography>
            <Typography component={'li'}>
              d. The Customer shall provide all relevant data in the form, type
              and manner specified by STÖRK in good time to enable the provision
              of services.
            </Typography>
            <Typography component={'li'}>
              e. Before use, the Customer shall thoroughly test the contractual
              services for usability and freedom from defects, if necessary with
              the existing hardware and software configuration.{' '}
            </Typography>
            <Typography component={'li'}>
              f. The Customer shall notify STÖRK of any defects by e-mail if
              STÖRK does not use a ticket system. The Customer shall document
              the description of the defect to the best of its ability in a
              comprehensible manner, stating the detailed circumstances of the
              defect's occurrence, its effects and – to the extent that the
              Customer is able to make statements in this regard – the possible
              causes, and shall report it to STÖRK within a reasonable period of
              time after its discovery.
            </Typography>
          </ol>
          <Typography component={'li'}>
            5.3. If the Customer does not have the required rights of use for
            the information to be provided to STÖRK, it shall inform STÖRK of
            this fact at the time of the handover. If STÖRK is held liable by
            third parties for an alleged infringement of third-party rights, the
            Customer shall fully indemnify STÖRK against liability.{' '}
          </Typography>
          <Typography component={'li'}>
            5.4. Any additional expenses incurred by STÖRK in providing
            cooperation at the request of the Customer shall be reimbursed by
            the Customer in accordance with the agreed rates of remuneration.
          </Typography>
          <Typography component={'li'}>
            5.5. If the Customer fails to comply with a duty to cooperate that
            is required for a service owed by STÖRK, STÖRK is entitled to
            temporarily suspend the performance until the Customer has fulfilled
            this duty to cooperate. Agreed completion dates shall be postponed
            accordingly, adding a reasonable start-up period. Should the
            Customer fail to fulfil its duty to cooperate even after a
            reasonable deadline has been set with the threat of refusal, STÖRK
            shall be entitled to terminate the contract with immediate effect.
            In this case, it shall furthermore be entitled to charge for all
            work performed up to that point on a time and materials basis in
            accordance with the remuneration agreed between the parties or in
            accordance with STÖRK's price list.
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          6. Acceptance
        </Typography>
        <ol>
          <Typography component={'li'}>
            6.1. Insofar as the activities to be performed by STÖRK are services
            under a contract for works and services, the Customer shall be
            obligated to accept such performance in stages or upon the
            performance of the services in accordance with the following
            provisions: After the performance for one stage or the performance
            overall has been completed, STÖRK shall provide notification of the
            readiness for acceptance. The acceptance shall be deemed successful
            if there are no material defects in the contractual performance. A
            partial acceptance only pertains to the freedom from defects of the
            partial performance. The interaction of the various parts of the
            total performance (overall functionality) is only determined upon
            final acceptance.
          </Typography>
          <Typography component={'li'}>
            6.2. If there are no significant defects preventing acceptance, the
            Customer shall be required to accept the performance. If the
            Customer does not judge the services provided to be substantially in
            compliance with the contract, it shall notify STÖRK of its
            complaints within 3 working days (Monday to Friday), observing at
            least text form.{' '}
          </Typography>
          <Typography component={'li'}>
            6.3. If the Customer complains about the performance in due time and
            in accordance with the contract, existing defects in STÖRK's own
            software shall be remedied within a reasonable period of time. Where
            third-party software is defective, STÖRK shall notify the respective
            software producer in order to effect the rectification of the
            defects.
          </Typography>
          <Typography component={'li'}>
            6.4. If, upon STÖRK's request, the Customer fails to specify the
            reasons for refusing acceptance at least in text form within the
            period specified in clause 6.2, acceptance shall be deemed to have
            been granted. Acceptance shall also be deemed to have taken place if
            the Customer uses the services provided by STÖRK for a period of
            more than 2 working days.
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          7. Rights of use
        </Typography>
        <ol>
          <Typography component={'li'}>
            7.1. Instructions, documentation, electronic presentations and other
            written material prepared by STÖRK in the course of fulfilling the
            contract shall be provided to the Customer in copy upon request for
            use in accordance with the contract for the Customer's own purposes.
            The Customer shall be obligated to observe existing statutory
            industrial property rights.{' '}
          </Typography>
          <Typography component={'li'}>
            7.2. STÖRK shall grant the Customer a simple, permanent,
            non-transferrable right to use the work results, such as concepts
            and specification documents, for its own purposes in accordance with
            their intended purpose.
          </Typography>
          <Typography component={'li'}>
            7.3. The SaaS solution is protected by copyright. The SaaS solution
            may include open-source software tools. These are subject to the
            respective open source licence terms which are included in the SaaS
            solution. If the contract also covers the provision of third-party
            software and the following provisions on rights of use do not apply,
            the granting of rights for the Customer shall be determined by the
            license terms of the licensor of the third-party software Ubuntu,
            Debian GNU/Linux, Alpine Linux, CentOS Linux, Kong, nginx, EMQX,
            OpenVPN, OneSignal. The licence terms for the third-party software
            can be accessed on the website of the respective licensor.{' '}
          </Typography>
          <Typography component={'li'}>
            7.4. Upon full payment of the agreed remuneration, STÖRK shall grant
            the Customer a simple right to use the customised or created
            software, and, in the event of the provision as standard on-premise
            solution in object code, a simple right of use for the intended
            purpose. Until payment of the remuneration owed at the agreed time,
            the Customer shall be granted a simple right of use, which may be
            revoked if payment is more than 30 days late.{' '}
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          8. Data protection / confidentiality{' '}
        </Typography>
        <ol>
          <Typography component={'li'}>
            8.1. STÖRK shall comply with all applicable provisions of data
            protection law in the performance of the contract; this includes in
            particular the General Data Protection Regulation (GDPR) and the
            German Federal Data Protection Law (BDSG). For details on data
            processing and the rights of data subjects, please review the data
            protection notice and data privacy statement under
            https://www.st-cloudconnect.com/privacy.
          </Typography>
          <Typography component={'li'}>
            8.2. The contracting parties shall bind their personnel and any
            subcontractors (if applicable) to these obligations under data
            protection law.
          </Typography>
          <Typography component={'li'}>
            8.3. The Customer shall comply with the applicable data protection
            provisions when using the contractual performance provided by STÖRK.
            In this respect, STÖRK is not the data controller within the meaning
            of Art. 4 No. 7 GDPR.
          </Typography>
          <Typography component={'li'}>
            8.4. The contracting parties agree to conclude a separate contract
            for commissioned data processing specifying the Customer's rights
            and obligations in connection with the commissioned data processing,
            provided that STÖRK processes personal data of the Customer or its
            customers Authorized 3rd Party Users in connection with the
            performance of the contract in accordance with the order.
          </Typography>
          <Typography component={'li'}>
            8.5. STÖRK undertakes to agree a provision identical to the above
            clause 8.2 with all personnel and subcontractors it employs in
            connection with the preparation, implementation and fulfilment of
            the contract with the Customer.
          </Typography>
          <Typography component={'li'}>
            8.6. Insofar as the contracting parties have concluded a
            non-disclosure agreement (NDA), its provisions shall take precedence
            over the following provisions. Furthermore, the contracting parties
            undertake to keep confidential all business secrets or information
            designated as confidential (collectively referred to as
            “Information”) that they receive or learn from the other party
            during the implementation of the contract for the duration of the
            contract implementation, and beyond the end of the contract for a
            further three years, and to obligate the respective personnel
            involved accordingly. The Information shall not be made accessible
            to third parties not involved in the implementation of the contract.
            The contracting parties shall secure the Information just as they do
            their own sensitive information. Companies affiliated with the
            contracting parties within the meaning of § 15 et seq. German Stock
            Corporation Act are not third parties within the meaning of this
            provision
          </Typography>
          <Typography component={'li'}>
            8.7. The obligations of the contracting parties set forth in clause
            8.6. shall not apply to information that (i) was already publicly
            known or known to the receiving party at the time of its disclosure
            by the disclosing party; (ii) has become publicly known after its
            disclosure by the disclosing party through no fault of the receiving
            party; ( (iii) which the receiving party can prove it has lawfully
            received from a third party without restriction in terms of
            confidentiality or use; (iv) which the receiving party has developed
            independently without making use of the disclosing party's
            information; (v) which must be disclosed pursuant to law,
            administrative decree or court order - provided that the receiving
            party shall inform the disclosing party thereof without undue delay
            and shall support it in the defence against such orders or decisions
            - or (vi) to the extent that the receiving party is permitted to use
            or disclose the information on the basis of mandatory statutory law
            or on the basis of the contract between the parties.{' '}
          </Typography>
          <Typography component={'li'}>
            8.8. STÖRK shall be authorised to name the Customer as a reference
            contracting party and may use its logo for this purpose.{' '}
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          9. Remuneration and terms of payment
        </Typography>
        <ol>
          <Typography component={'li'}>
            9.1. The amount of the remuneration for the contractual services to
            be provided by STÖRK and the terms of payment (payment dates) are
            set out in the SaaS contract.{' '}
          </Typography>
          <Typography component={'li'}>
            9.2. In addition to the agreed remuneration, STÖRK shall also be
            entitled to reimbursement of incidental costs and expenses, in
            particular reimbursement for reasonable travelling and accommodation
            costs, if agreed in the individual case. Incidental costs will also
            be billed to the Customer.{' '}
          </Typography>
          <Typography component={'li'}>
            9.3. The amounts billed by STÖRK shall be due for payment upon
            receipt of the invoice by the Customer and shall be payable within
            14 calendar days without deductions. The timeliness of a payment is
            determined by the date the amount is credited to the receiving
            account.{' '}
          </Typography>
          <Typography component={'li'}>
            9.4. If the Customer is in default with its payment obligation,
            STÖRK shall be entitled to charge default interest in accordance
            with the law. STÖRK reserves the right to claim further damages, in
            particular higher interest, additional costs and reasonable reminder
            fees. However, the Customer shall be entitled to prove that STÖRK
            has incurred no damage at all or a lower damage.{' '}
          </Typography>
          <Typography component={'li'}>
            9.5. All amounts are net plus the statutory value added tax
            applicable at the time of performance.
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          10. Material defects and defects of title for the provision of
          IT-specific services
        </Typography>
        <ol>
          <Typography component={'li'}>10.1. Definition of defect </Typography>
          <Typography component={'li'}>
            Technical data, specifications and performance data in public
            statements, in particular in advertising material, shall not be
            considered a specification of qualities. STÖRK warrants that the
            object of the contract will be in proper working order and
            operational in accordance with the agreed requirements. In all other
            respects, the object of the contract must be suitable for the use
            assumed under the concluded contract and otherwise have a quality
            that is customary for a contractual item of the same type. A defect
            in title exists if the necessary rights for the contractual use of
            the object of the contract in Europe, the USA and Canada could not
            be validly granted to the Customer.
          </Typography>
          <Typography component={'li'}>
            10.2. Changes by the Customer{' '}
          </Typography>
          <Typography component={'li'}>
            Should the Customer or third parties contracted by it interfere with
            the contractual item delivered or the work performed by STÖRK
            (without the consent of STÖRK, granted at least in text form), in
            particular by means of manipulation or other changes, STÖRK will
            only remedy the defect if the Customer can prove that no connection
            exists between the interference and the defect and that the
            interference does not impede the analysis and rectification.{' '}
          </Typography>
          <Typography component={'li'}>
            10.3. Exclusion of subsequent performance
          </Typography>
          <Typography component={'li'}>
            The Customer's claim to subsequent performance shall be excluded if
            the defect cannot be reproduced or cannot be demonstrated on the
            basis of handwritten or machine-recorded printouts.{' '}
          </Typography>
          <Typography component={'li'}>
            10.4. If STÖRK provides services relating to the search for or the
            rectification of defects without being obligated to do so, it may
            demand remuneration for such services in accordance with the agreed
            remuneration rates. This shall apply in particular if a defect
            cannot be proven or if it is not attributable to STÖRK. STÖRK shall
            also be reimbursed for any additional expenses incurred as a result
            of the Customer's failure to fulfil its duties to cooperate in
            accordance with the contract.
          </Typography>
          <Typography component={'li'}>
            10.5. Subsequent performance for defects in title
          </Typography>
          <Typography component={'li'}>
            Cure of defects in title shall be at the STÖRK's discretion, either
            (i) by providing the Customer with a legally proper way to use the
            contractual performance, (ii) by modifying the contractual
            performance which infringes the industrial property rights without
            any effects on its function or only with effects on its function
            which are acceptable for the Customer, (iii) by replacing the
            infringing contractual performance with a contractual performance
            whose contractual use does not infringe on any industrial property
            right, without any effect on the functions of the contractual
            performance or only with the effects which are acceptable for the
            Customer, or, (iv) regarding software, STÖRK may deliver a new
            program version whose contractual use does not infringe on any
            third-party rights.{' '}
          </Typography>
          <Typography component={'li'}>
            The Customer shall inform STÖRK without undue delay in text form,
            should any third parties assert industrial property rights against
            the Customer. STÖRK may, at its option, defend against claims or
            satisfy them. The Customer shall not accept claims of third parties
            on its own initiative. STÖRK shall indemnify the Customer against
            all statutory costs and losses associated with the defence against
            claims to the extent that STÖRK is answerable for the defect in
            title, such defect is not caused by any breach of duty on the part
            of the Customer and the Customer fulfilled its notification
            obligation without delay.
          </Typography>
          <Typography component={'li'}>
            10.6. Fraudulent intent / guarantee
          </Typography>
          <Typography component={'li'}>
            In the event of fraudulent intent or the assumption of a guarantee
            by STÖRK, the statutory provisions for material defects and defects
            in title shall remain unaffected.
          </Typography>
          <Typography component={'li'}>
            10.7. Claims for other breaches of duty{' '}
          </Typography>
          <Typography component={'li'}>
            The Customer may only derive rights from other breaches of duty by
            STÖRK if it has notified STÖRK thereof at least in text form and if
            it has granted STÖRK a grace period to remedy the breach. This shall
            not apply if, due to the nature of the breach of duty, a remedy is
            out of the question. The limits set out in clause 11 shall apply to
            compensation for damages or reimbursement of fruitless expenses.
          </Typography>
          <Typography component={'li'}>10.8. Limitation periods</Typography>
          <Typography component={'li'}>
            The limitation period for claims for rectification of defects shall
            be one year. The limitation period shall begin with the start of use
            of the contractual performance. In the event of intent or gross
            negligence on the part of STÖRK, fraudulent concealment of the
            defect, personal injury or defects of title, and in the event of
            claims under the Product Liability Act and in for guarantees, the
            statutory limitation periods shall apply.
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          11. Liability
        </Typography>
        <ol>
          <Typography component={'li'}>
            11.1. Where the Customer claims damages or the reimbursement of
            expenses due to intent or gross negligence on the part of STÖRK, or
            where the Customer asserts claims for culpable injury to life, body
            or health or under the ProdHG, STÖRK shall be liable in accordance
            with the law. The same shall apply if a defect is fraudulently
            omitted, if a procurement risk is assumed in accordance with § 276
            BGB or if (by way of exception) STÖRK gives a written guarantee with
            respect to characteristics within the meaning of § 443 BGB.
          </Typography>
          <Typography component={'li'}>
            11.2. In the event that STÖRK's services are obtained by
            unauthorized third parties using the Customer's access credentials,
            the Customer shall be liable for the resulting fees in accordance
            with civil liability until the Customer's directive to change the
            access data is received or the loss or theft is reported, provided
            that the Customer is at fault for the access of the unauthorized
            third party.{' '}
          </Typography>
          <Typography component={'li'}>
            11.3. For simple negligence, the STÖRK shall only be liable for
            breaches of cardinal contractual duties. Cardinal contractual
            obligations are the essential obligations which form the foundation
            of the contract, which were decisive for the conclusion of the
            contract and on whose fulfilment the Customer can rely. In such a
            case, the amount of STÖRK's liability shall be limited to the amount
            of the foreseeable damage that can typically be expected to occur.
            However, STÖRK's liability shall be limited per loss event, in the
            case of SaaS, to a maximum of 1.5 times the annual remuneration,
            twice the remuneration in the case of services and deliveries under
            a contract for work and services and twice the respective
            remuneration or corresponding partial remuneration amount (net
            amount in each case) in the case of IT-specific services. In other
            regards, STÖRK's liability for damage caused by slight negligence
            relating to other contractual obligations and for lost profits and
            additional labour costs incurred by the Customer, loss of use and/or
            revenue losses shall be excluded.
          </Typography>
          <Typography component={'li'}>
            11.4. Strict liability for damages for defects already present at
            the time of contract conclusion shall be excluded.
          </Typography>
          <Typography component={'li'}>
            11.5. In the event of data loss, STÖRK shall only be liable for the
            damage that would have occurred even if the Customer had backed up
            the data in accordance with the contract and if the Customer had
            fulfilled its data backup obligations.{' '}
          </Typography>
          <Typography component={'li'}>
            11.6. STÖRK shall not be liable for damages caused by defective
            third-party software that is covered by the contract, including
            open-source software and open-source components (“third-party
            software”), unless the defect is recognizable or avoidable for
            STÖRK. STÖRK shall not be held liable for defects in third-party
            software in accordance with § 278 BGB if STÖRK is obligated to grant
            rights with regard to the third-party software and to make it
            available.
          </Typography>
          <Typography component={'li'}>
            11.7. Any additional liability of STÖRK for damages or the
            reimbursement of expenses shall be precluded regardless of the legal
            nature of the claim raised.{' '}
          </Typography>
          <Typography component={'li'}>
            11.8. To the extent that STÖRK's liability is excluded or restricted
            under the foregoing, this shall also apply for the liability of the
            corporate bodies of STÖRK as well as any vicarious agents or any
            persons used in the performance of its contractual obligations, in
            particular employed personnel.{' '}
          </Typography>
          <Typography component={'li'}>
            11.9. The Customer shall notify STÖRK in writing and without undue
            delay of any damage within the meaning of the above liability
            provisions, or have such damage recorded by STÖRK, so that STÖRK is
            informed as early as possible and can possibly work together with
            the affected Customer to mitigate the damage.
          </Typography>
          <Typography component={'li'}>
            11.10. The limitation periods are regulated in clause 10.8.
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          12. Term / termination
        </Typography>
        <ol>
          <Typography component={'li'}>
            12.1. The SaaS contract contains the primary provisions regarding
            the contractual term and the notice period.{' '}
          </Typography>
          <Typography component={'li'}>
            12.2. If the contract for the service to be provided is a contract
            for continuing obligations, the contract begins at the agreed time
            and runs for an indefinite period.{' '}
          </Typography>
          <Typography component={'li'}>
            12.3. In the case of a contract for continuing obligations in
            accordance with clause 12.2, both contracting parties may terminate
            the concluded contract at any time by giving three months' notice to
            the end of the month.{' '}
          </Typography>
          <Typography component={'li'}>
            12.4. In the event of timely cancellation, remuneration shall be as
            follows: Full payment shall be due for the performance rendered up
            to that point. The claim to remuneration for performance that cannot
            be rendered as a result of premature termination shall be cancelled,
            unless the Customer is answerable for the termination. If the
            Customer terminates a contract for work and services in accordance
            with § 648 BGB, STÖRK may, at its discretion, assert claims in
            accordance with § 648 BGB, or may instead, in addition to the
            remuneration for the services already rendered, demand a lump sum of
            50% of the remuneration (in regard to this contract) for the
            services not yet provided at the time of termination as remuneration
            for the respective service and its expenses and lost profits.{' '}
          </Typography>
          <Typography component={'li'}>
            STÖRK reserves the right to prove that it is entitled to a higher
            amount in accordance with § 648 BGB. The Customer, for its part, has
            the right to prove that the lump sum in favour of STÖRK is lower.
          </Typography>
          <Typography component={'li'}>
            12.5. This shall not affect the right of both contracting parties to
            terminate the contract without notice for good cause. Such good
            cause shall be present, in particular:{' '}
          </Typography>
          <ol>
            <Typography component={'li'}>
              -if a contracting party, despite a warning, repeatedly and
              persistently breaches obligations under the contract or the
              provisions of the General Terms and Conditions, which apply on a
              subordinate and supplementary basis,{' '}
            </Typography>
            <Typography component={'li'}>
              -if the Customer is delinquent in paying the compensation by more
              than one month;{' '}
            </Typography>
            <Typography component={'li'}>
              -if the Customer experiences a significant deterioration in its
              financial position, leading to a Credit form creditworthiness
              index of 490 or lower;{' '}
            </Typography>
            <Typography component={'li'}>
              -if insolvency proceedings have been opened against the assets of
              the other contracting party or the opening of insolvency
              proceedings has been refused for lack of assets.
            </Typography>
          </ol>
          <Typography component={'li'}>
            Special provisions for SaaS contract
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          13. Data backup by STÖRK and Customer
        </Typography>
        <ol>
          <Typography component={'li'}>
            13.1. STÖRK shall ensure that the sensor and event data are
            automatically stored in accordance with the provisions of § 2 (4)
            lit. b of the SaaS contract.{' '}
          </Typography>
          <Typography component={'li'}>
            13.2. STÖRK undertakes to protect the sensor and event data from
            access by unauthorised third parties.{' '}
          </Typography>
          <Typography component={'li'}>
            13.3. The Customer grants STÖRK the right to process and reproduce
            the sensor and event data stored by it for the purpose of contract
            performance (in particular data backup), to the extent that this is
            necessary for the provision of the services owed by STÖRK under the
            contract or for security reasons. STÖRK shall also be entitled to
            store the sensor and event data in a backup data centre in the
            European Union. STÖRK shall furthermore be authorised to make
            changes to the data structure or the data format of the sensor and
            event data in order to rectify faults. The Customer shall in all
            other regards be the sole authorised user of the sensor and event
            data.{' '}
          </Typography>
          <Typography component={'li'}>
            13.4. The Customer shall be obligated to download the sensor and
            event data to its hardware in a commonly used, processable data
            format in good time before the expiration of the 12-month period in
            accordance with clause 2 (4) (b) (iv) of the SaaS contract. The
            Customer shall not be entitled to receive for its use the software
            suitable for using the data.{' '}
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          14. Availability Cloud Connect{' '}
        </Typography>
        <ol>
          <Typography component={'li'}>14.1. Availability</Typography>
          <Typography component={'li'}>
            STÖRK guarantees the availability of Cloud functionalities with a
            monthly availability percentage [1] of at least 98.5%.
          </Typography>
          <Typography component={'li'}>
            [1] The “monthly availability percentage” is calculated by
            subtracting the percentage of minutes in which Cloud Connect was
            unavailable as a computer instance from 100%.
          </Typography>
          <Typography component={'li'}>
            14.2. The monthly availability of Cloud Connect is measured in
            accordance with the specifications of § 2 (2) of the SaaS contract,
            less scheduled and unscheduled support in accordance with the
            provisions in clauses 14.4. and 14.5. as follows:
          </Typography>
          <Typography component={'li'}>
            <LightTable
              columns={[
                {
                  field: 'availability',
                  headerName: 'Monthly availability',
                },
                {
                  field: 'availability_minutes',

                  headerName: 'Maximum monthly availability in minutes',
                },
                {
                  field: 'availability_downtime',

                  headerName: 'Maximum permitted downtime in minutes',
                },
              ]}
              rows={[
                {
                  availability: '100.0%',
                  availability_minutes: '30.44 T * 24 h* 60 min = 43834 min',
                  availability_downtime: '0',
                },
                {
                  availability: '98.5%',
                  availability_minutes: '43176 min',
                  availability_downtime: '658 min',
                },
              ]}
            ></LightTable>
          </Typography>
        </ol>
        <Typography component={'li'}>
          14.3. Availability control / monitoring
        </Typography>
        <Typography component={'li'}>
          An independent computer instance continuously checks and monitors the
          availability of Cloud Connect, in particular the availability of the
          device manager, data storage and alert functions (hereinafter
          collectively referred to as “Functions”) of Cloud Connect. This
          computer instance is hosted in a server area separate from Cloud
          Connect to ensure that the availability of Cloud Connect as a
          functional instance can be checked independently. “Unavailable” and
          “not available” mean that Cloud Connect as a SaaS solution can no
          longer fulfil one of the Functions.
        </Typography>
        <Typography component={'li'}>
          14.4. STÖRK is entitled to temporarily restrict availability due to
          unforeseeable, urgent technical support services for the maintenance
          and repair of Cloud Connect, in particular due to threats to the
          system security of the server (unscheduled support). Such support
          services include in particular security bug fixes, the rectification
          of security problems in externally accessible components, namely, web
          servers, databases, security-relevant system libraries, defense
          measures against cyber attacks, the replacement of defective hardware,
          measures against sabotage, etc. Such permissible measures include, in
          particular, the rebooting of servers. STÖRK shall be entitled to
          perform unscheduled support services at its own dutiful discretion and
          without prior consultation with the Customer. All activities must be
          documented and communicated to the Customer promptly and unasked.
        </Typography>
        <Typography component={'li'}>
          14.5. STÖRK is further authorized to temporarily limit the
          availability due to technically necessary, scheduled support services
          for the maintenance of the contractual services (scheduled support).
          Support includes in particular the import of new programme versions
          and the replacement of hardware on/in components of the SaaS
          infrastructure. In other regards, adaptations, changes or additions to
          the software, as well as measures to identify and rectify malfunctions
          (in particular defect rectification), may only lead to a temporary
          interruption or impairment of accessibility if this is necessary for
          technical reasons.{' '}
        </Typography>
        <Typography component={'li'}>
          14.6. STÖRK shall be obligated to make all commercially and
          technically reasonable efforts to keep unscheduled and scheduled
          support services as brief as possible and to complete them as quickly
          as is possible in view of expenses and personnel. STÖRK shall always
          and in every case refrain from arbitrarily setting and/or performing
          scheduled support services. STÖRK shall notify the Customer in good
          time and at least in text form of scheduled support and any resulting
          foreseeable restrictions in availability.
        </Typography>
        <Typography component={'li'}>
          14.7. STÖRK shall coordinate the support times ensuring proper
          business operations, insofar as this is reasonable in view of the
          circumstances necessitating the support services.{' '}
        </Typography>
        <Typography component={'li'}>14.8. Remuneration credit</Typography>
        <Typography component={'li'}>
          In the event that, subject to the restrictions in Clause 15.8, any of
          the Functions in Cloud Connect is not available as agreed, the
          following compensation credit percentages shall apply: Remuneration
          credit
        </Typography>

        <Typography component={'li'}>
          <LightTable
            columns={[
              {
                field: 'availability',
                headerName: 'Monthly availability',
              },
              {
                field: 'downtime_minutes',

                headerName: 'Downtime in minutes',
              },
              {
                field: 'remuneration',

                headerName: 'Remuneration credit percentage',
              },
            ]}
            rows={[
              {
                availability:
                  'less than 98.5%, but equal to or greater than 97.5%',
                downtime_minutes: '877 < t <= 1096',
                remuneration: '10%',
              },
              {
                availability:
                  'less than 98.5%, but equal to or greater than 97.0%',
                downtime_minutes: '877 < t <= 1315',
                remuneration: '25%',
              },
              {
                availability: 'less than 97%',
                downtime_minutes: 't < 1315',
                remuneration: '50%',
              },
            ]}
          />
        </Typography>

        <Typography component={'li'}>
          14.9. Remuneration credits are calculated as a percentage of the
          monthly remuneration paid by the Customer. Each remuneration credit
          shall only be offset against future remuneration still to be paid by
          the Customer. Remuneration credits do not entitle the Customer to
          claim refunds or other payments from STÖRK - subject to claims for
          damages. The remuneration credit shall only be offset against the
          remuneration if the credit amount for the corresponding monthly
          billing cycle is greater than EUR 5. Remuneration credits are not
          transferable to other Customer accounts.{' '}
        </Typography>
        <Typography component={'li'}>
          14.10. Remuneration credits will only be processed after the Customer
          has submitted a corresponding request, observing at least text form,
          to STÖRK's sales department. This request must be received by STÖRK no
          later than the end of the second billing cycle after the change takes
          effect and must include the following information:
        </Typography>
        <Typography component={'li'}>
          ­The words “Cloud Connect SLA – Application for Compensation credit”;
        </Typography>
        <Typography component={'li'}>
          ­Date, time and relevant user account;
        </Typography>
        <Typography component={'li'}>
          ­Detailed documentation of the incident, e.g. excerpts from Cloud
          Connect diagrams.
        </Typography>
        <Typography component={'li'}>
          The supplementary assertion of claims for reduction in accordance with
          clause 17.5 is excluded.
        </Typography>
        <Typography variant="h3" color={'primary'} component={'li'}>
          15. Service Level Agreement (SLA) for Cloud Connect
        </Typography>
        <ol>
          <Typography component={'li'}>
            15.1. In providing Cloud Connect as an SaaS solution, STÖRK will
            provide support services exclusively for the Customer in accordance
            with the following provisions during the agreed term. The fee for
            using the SaaS services also covers the provision of support
            services.
          </Typography>
          <Typography component={'li'}>
            15.2. The support services include{' '}
          </Typography>
          <ol>
            <Typography component={'li'}>
              a. the rectification of defects;
            </Typography>
            <Typography component={'li'}>
              b. the provision of new programme versions and
            </Typography>
            <Typography component={'li'}>
              c. the support helpdesk for the Customer, providing professional
              and technical support in the event of problems with the use of
              Cloud Connect.
            </Typography>
          </ol>
          <Typography component={'li'}>
            Additional services shall be remunerated separately at the agreed
            hourly or daily rate.{' '}
          </Typography>
          <Typography component={'li'}>
            15.3. Notification of defects by the Customer
          </Typography>
          <Typography component={'li'}>
            The Customer shall notify STÖRK by e-mail of any
            malfunctions/defects (hereinafter collectively referred to as
            “defects”) of Cloud Connect with a corresponding description (see
            clause 5.2. lit. f.). STÖRK shall analyse the defects during service
            hours. These are Monday - Friday from 09:00 to 16:00 CET (except on
            public holidays throughout Germany). STÖRK shall use its best
            endeavours to commence qualified measures to rectify defects within
            a reasonable period of time – if possible within 6 hours – during
            business hours.{' '}
          </Typography>
          <Typography component={'li'}>
            15.4. Form of defect rectification
          </Typography>
          <Typography component={'li'}>
            STÖRK will, at its own discretion, remedy the reported or
            self-diagnosed defects by one of the following measures:
          </Typography>
          <ol>
            <Typography component={'li'}>
              a. By installing new programme versions;{' '}
            </Typography>
            <Typography component={'li'}>
              b. provision of instructions to the Customer to avoid the problem
              or to rectify the defect (workaround). The Customer shall have
              such instructions carried out by competent personnel, if this is
              not unreasonable.{' '}
            </Typography>
          </ol>
          <Typography component={'li'}>
            15.5. Provision of new programme versions
          </Typography>
          <ol>
            <Typography component={'li'}>
              a. STÖRK's obligation to provide new programme versions shall
              include the installation after the respective release.{' '}
            </Typography>
            <Typography component={'li'}>
              b. The user documentation will be adapted to the current programme
              version.{' '}
            </Typography>
          </ol>
          <Typography component={'li'}>
            15.6. Support helpdesk for the Customer
          </Typography>
          <Typography component={'li'}>
            STÖRK is obliged under the terms of the service contract to provide
            the Customer with a user hotline that can be reached during STÖRK's
            service hours. The sole purpose of the user hotline is to support
            the Customer in utilizing the services provided by STÖRK under the
            SaaS contract and to report malfunctions by the Customer.{' '}
          </Typography>
          <Typography component={'li'}>
            15.7. Compensation for non-existent defects
          </Typography>
          <Typography component={'li'}>
            If it turns out that a defect reported by the Customer does not
            actually exist or is not due to any other breach of duty on the part
            of STÖRK, STÖRK shall be entitled to charge the Customer for the
            costs incurred as a result of the analysis and the defect
            rectification work that has been started, based on the actual costs
            incurred, and the Customer shall reimburse STÖRK for these costs.{' '}
          </Typography>
          <Typography component={'li'}>
            15.8.STÖRK shall not be obligated to provide support services in
            accordance with Clause 15.2. a. and b. in the following
            circumstances:{' '}
          </Typography>
          <ol>
            <Typography component={'li'}>
              a. if the Customer is answerable for the reported or occurring
              defects;
            </Typography>
            <Typography component={'li'}>
              b. acts or omissions by the Customer in breach of contract or the
              law;
            </Typography>
            <Typography component={'li'}>
              c. hardware, software or other peripheral systems used by the
              Customer that do not conform with the system requirements
              indicated by STÖRK;
            </Typography>
            <Typography component={'li'}>
              d. in the event of malfunctions that lie outside STÖRK's sphere of
              control and responsibility;
            </Typography>
            <Typography component={'li'}>
              e. events of force majeure in accordance with clause 3.3;
            </Typography>
            <Typography component={'li'}>
              f. malfunctions caused by the Customer's connection to the
              Internet, the maintenance of the network connection.
            </Typography>
          </ol>
        </ol>

        <Typography variant="h3" color={'primary'} component={'li'}>
          16. Other obligations of the Customer
        </Typography>
        <ol>
          <Typography component={'li'}>
            16.1. The Customer will establish the data connection between the
            where locations it intends to use the system and the data transfer
            point defined by STÖRK via the internet at its own expense. The
            Customer is solely responsible for operating and maintaining this
            data connection, and shall bear the related costs.
          </Typography>
          <Typography component={'li'}>
            16.2. When using the SaaS services, the Customer shall always comply
            with the applicable statutory and official provisions, as amended,
            and shall provide STÖRK with appropriate evidence of such compliance
            upon request.
          </Typography>
          <Typography component={'li'}>
            16.3. The Customer shall treat the access data confidentially and
            store them so that they cannot be accessed by any unauthorized third
            party, in order to make the misuse of access data impossible. As
            soon as the Customer sees any indications that the access data has
            been obtained unlawfully by an unauthorized third party or could be
            misused, the Customer shall inform STÖRK without undue delay,
            observing at least text form.
          </Typography>
          <Typography component={'li'}>
            16.4. The Customer shall notify STÖRK in text form of all changes to
            its company that are relevant for the performance of the services
            owed by STÖRK under the contract. This includes in particular
            changes to the business address, company or legal form as well as
            the responsible contact person on the side of the Customer.
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          17.Material defects and defects in title in the SaaS solution
        </Typography>
        <ol>
          <Typography component={'li'}>
            17.1. STÖRK shall guarantee the functionality and operational
            readiness of the SaaS solution in accordance with the provisions of
            this contract. Technical data, specifications and performance data
            in public statements, in particular in advertising material/product
            flyers, shall not be considered a specification of qualities. In all
            other respects, the SaaS solution shall be required to be suitable
            for the use assumed under the concluded contract and otherwise have
            a quality that is customary for a SaaS solution of the same type. A
            defect in title exists if the necessary rights for the contractual
            use of the object of the contract in Europe, the USA and Canada
            could not be validly granted to the Customer.
          </Typography>
          <Typography component={'li'}>
            17.2. Should the Customer or third parties contracted by it
            interfere with the SaaS solution provided by STÖRK (without the
            consent of STÖRK, granted at least in text form), in particular by
            means of manipulation or other changes, STÖRK will only remedy the
            defect if the Customer can prove that no connection exists between
            the interference and the defect and that the interference does not
            impede the analysis and rectification.{' '}
          </Typography>
          <Typography component={'li'}>
            17.3. The Customer's claim to subsequent performance shall be
            excluded if the defect cannot be reproduced or cannot be
            demonstrated on the basis of handwritten or machine-recorded
            printouts.{' '}
          </Typography>
          <Typography component={'li'}>
            17.4. The Customer shall report defects in Cloud Connect in
            accordance with clause 15.3. The obligation to maintain does not
            include the adaptation of the SaaS solution to changed operating
            conditions and technical and functional developments, such as
            changes to the IT environment, in particular changes to the hardware
            or operating system, adaptation to the feature scope of competing
            products or establishing compatibility with new data formats. In the
            event of justified notices of Defects, STÖRK shall remedy the
            defects in accordance with the provisions of clauses 15.4 and 15.5.
          </Typography>
          <Typography component={'li'}>
            17.5. If STÖRK is unsuccessful in remedying the defect in the second
            attempt after an additional reasonable time period, the Customer may
            reduce the agreed remuneration pro rata for the periods during which
            the SaaS solution was not available to the agreed extent. The amount
            of the right of reduction shall be limited to the monthly
            remuneration attributable to the defective component of the service.
          </Typography>
          <Typography component={'li'}>
            17.6. The provision in clause 10.5 shall apply accordingly to
            subsequent performance in the event of defects in title.{' '}
          </Typography>
          <Typography component={'li'}>
            17.7. The limitation period for claims for the rectification of
            defects in the SaaS solution shall be one year.
          </Typography>
          <Typography component={'li'}>
            Special provisions on IT-related services
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          18. Other IT-related services as object of the contract
        </Typography>
        <Typography component={'li'}>
          {' '}
          If STÖRK provides services such as software installation, programming
          of additional program components (software creation), data transfer,
          conversion, parameterization, instruction in the use of software and
          hardware, training, consulting and project management, customizing
          such as adapting (with changes to the source code) or setting
          up/adjusting (parameterization) the software, setting up hardware, or
          creating specification documents, the provisions in clause 19 f shall
          apply additionally.
        </Typography>
        <Typography variant="h3" color={'primary'} component={'li'}>
          19. Service provision and scope / restrictions
        </Typography>
        <ol>
          <Typography component={'li'}>
            19.1. The offer contains the services to be provided, together with
            stipulations, such as in regard to their specific purpose, the
            remuneration, the contract term, place of performance, time frame,
            etc.
          </Typography>
          <Typography component={'li'}>
            19.2. STÖRK reserves the right to select and organise its involved
            personnel.{' '}
          </Typography>
          <Typography component={'li'}>
            19.3. STÖRK shall hand over to the Customer the documentation of the
            work results of the respective services rendered at the latest upon
            full payment of the remuneration.{' '}
          </Typography>
          <Typography component={'li'}>
            19.4. Claims of the Customer for the provision of commissioned and
            prepaid services shall lapse without any repayment obligation on the
            part of STÖRK if they are not claimed by the Customer within 12
            months of the order being placed.
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          20. Special provisions for training courses{' '}
        </Typography>
        <ol>
          <Typography component={'li'}>
            20.1. Participation in training courses shall require the binding
            registration of participants by name, at least in text form. The fee
            and any additional costs shall be paid before the training course
            begins. In the event of cancellation of participation, STÖRK must be
            notified at least in text form at least 2 weeks before the start of
            the event. In this case, the cancellation fee shall be 1/3 of the
            fee due. If the cancellation is made within a shorter period or if a
            participant fails to attend the training course, the agreed fee and
            ancillary costs are due for payment in full.
          </Typography>
          <Typography component={'li'}>
            20.2. STÖRK reserves the right to cancel the training course.
            Registered participants will be notified as early as possible,
            unless force majeure prevents this. Fees already paid by
            participants shall be refunded. STÖRK shall compensate the
            participants for any damage incurred only subject to the terms of
            clause 11 of the GTC.{' '}
          </Typography>
          <Typography component={'li'}>
            20.3. The content and quality of the training course are based on
            the latest generally available knowledge relating to the subject
            matter. STÖRK accepts no liability for incomplete or incorrect
            content and/or accompanying materials.
          </Typography>
        </ol>
        <Typography component={'li'}>General final provisions</Typography>
        <Typography variant="h3" color={'primary'} component={'li'}>
          21. Form requirements
        </Typography>
        <ol>
          <Typography component={'li'}>
            21.1. No verbal collateral agreements have been made; amendments or
            supplements to the GTC and to the contracts concluded between STÖRK
            and the Customer shall require the written form to be effective. The
            formal requirement may also be met by using a simple electronic
            signature or a qualified electronic signature within the meaning of
            § 126a BGB (e.g. DocuSign). § 127 (2) and (3) BGB shall not apply in
            these two cases.{' '}
          </Typography>
          <Typography component={'li'}>
            21.2. Any notice of termination must observe at least the written
            form.
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          22. Place of performance / jurisdiction / governing law
        </Typography>
        <ol>
          <Typography component={'li'}>
            22.1. The place of performance for deliveries and services, as well
            as the place of subsequent performance, shall be Stuttgart.
          </Typography>
          <Typography component={'li'}>
            22.2. These GTC and contracts concluded on their basis shall be
            governed by the law of the Federal Republic of Germany, excluding
            the UN Convention on Contracts for the International Sale of Goods.{' '}
          </Typography>
          <Typography component={'li'}>
            22.3. If the Customer is a merchant (Kaufmann), a legal person under
            public law or a special entity under public law, the place of
            jurisdiction shall for any disputes shall be Stuttgart. STÖRK shall
            be entitled to pursue its own claims at the place of jurisdiction
            applicable for the Customer.{' '}
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          23. Assignment / severability
        </Typography>
        <ol>
          <Typography component={'li'}>
            23.1. The Customer may only assign claims from contracts concluded
            with STÖRK with the prior written consent of STÖRK. STÖRK may not
            withhold such consent unreasonably.
          </Typography>
          <Typography component={'li'}>
            23.2. Should individual provisions in these GTC be or become
            partially or wholly ineffective, or should these GTC have gaps, this
            shall not affect the validity of the remaining provisions.{' '}
          </Typography>
        </ol>
        <Typography variant="h3" color={'primary'} component={'li'}>
          24. Language and priority of the German language version
        </Typography>
        <Typography component={'li'}>
          These GTC and the SaaS contract have been drawn up in German. If they
          are translated into the English language, the German language version
          shall prevail in the event of any contradictions in the content of the
          two language versions.{' '}
        </Typography>
      </Box>
    </>
  );
}

export default TermsContent;
